/* eslint-disable indent */
import React, { useState } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"

import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import "lazysizes"

import makeStyles from "@material-ui/core/styles/makeStyles"

import SEO from "../components/SEO"
import SectionTitle from "../components/SectionTitle"
import ContactPopup from "../components/contacts/ContactPopup"
import Breadcrumbs from "../components/Breadcrumbs"

import logo from "../img/logo_monza_ares_help_blue.png"

const useStyles = makeStyles(theme => ({
  image: {
    display: "block",
    maxHeight: 200,
    width: "100%",
  },
  helperBlock: {
    maxWidth: 400,
    margin: '0 auto',
  },
  helperEntry: {
    marginTop: 20,
    marginBottom: 10,
    color: theme.palette.aresBlue.main,
    fontSize: 30,
  },
  helperImage: {
    width: '75%',
    display: 'block',
    margin: '0 auto'
  },
  helperButton: {
    background: theme.palette.customOrange.main,
    color: theme.palette.common.white,
    textTransform: 'unset',
    borderRadius: 20,
    width: '100%',

    "&:hover": {
      background: theme.palette.aresBlue.main,
    }
  }
}))

const getSchema = guide => {
  let schema = guide.data.schema_json.text || ""

  return schema
}

const GuidePage = props => {
  const {
    data: { guide },
    pageContext: { locale, pageSlug, breadcrumbs },
    location,
  } = props

  const [contactModalOpen, setContactModalOpen] = useState(false)


  const classes = useStyles()

  return (
    <>
      <SEO
        schema={getSchema(guide)}
        ogp={guide.data.ogp_tags || ""}
        meta={{
          title: guide.data.meta_title,
          description: guide.data.meta_description,
          keywords: guide.data.meta_keywords,
          author: "Medicina Interventionala",
        }}
        locale={locale}
        breadcrumbs={breadcrumbs}
        noindex={guide.data.noindex}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>

            <Grid item xs={12}>
              {guide.data.image.gatsbyImageData && (
                <GatsbyImage
                  alt={guide.data.title.text}
                  image={guide.data.image.gatsbyImageData}
                  className={classes.image}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {guide.data.title.text}{" "}
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              {guide.data.body1 &&
                guide.data.body1.map(slice => (
                  <div key={shortid.generate()}>
                    {slice.slice_type === "repeatable_image_type" && (
                      <div className="sliceRepeatableImageType">
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                      </div>
                    )}

                    {slice.slice_type === "list" && (
                      <Grid
                        container
                        className={classes.sliceListType}
                        direction="column"
                      >
                        <Grid item>
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                        </Grid>
                        <Grid item sm={10}>
                          <List disablePadding>
                            {slice.items.map(item => (
                              <ListItem
                                alignItems="flex-start"
                                key={shortid.generate()}
                                className={classes.listItem}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    alt={item.list_item_content}
                                    src={
                                      item.list_item_image.gatsbyImageData
                                        .images.fallback.src
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={item.list_item_content}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    )}

                    {slice.slice_type === "simple" && (
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: slice.primary.content.html,
                        }}
                      />
                    )}

                    {slice.slice_type === "dynamic_banner" && (
                      <div className={classes.dynamicBanner}>

                        {slice.items
                          .filter(
                            item =>
                              item.banner_type === null ||
                              item.banner_type === "contact" ||
                              item.banner_type === "promotion" ||
                              item.banner_type === "consultant"
                          )
                          .map(item => (
                            <div key={shortid.generate()}>
                              <DynamicBanner
                                data={item}
                                location={location}
                                locale={locale}
                              />
                            </div>
                          ))}

                      </div>
                    )}
                  </div>
                ))}
            </Grid>
            <Grid item xs={12}>
              <Hidden only={['md', 'lg', 'xl']}>
                <GatsbyImage
                  alt={guide.data.title.text}
                  image={guide.data.content_image_mobile.gatsbyImageData}

                />
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <GatsbyImage
                  alt={guide.data.title.text}
                  image={guide.data.content_image_desktop.gatsbyImageData}

                />
              </Hidden>
            </Grid>
            {guide.data.helper.document && <Grid item xs={12}>
              <div className={classes.helperBlock}>
                <img
                  src={logo}
                  alt="MONZA ARES"
                  className="logoWhiteNoBg"
                  style={{ width: 250 }}
                />
                <div className={classes.helperEntry}>
                  <strong>{guide.data.helper.document.data.name.text}</strong> îți răspunde la toate întrebările legate de această procedură
                </div>

                <GatsbyImage
                  alt={guide.data.helper.document.data.name.text}
                  image={guide.data.helper.document.data.profile_image_no_background.gatsbyImageData}
                  className={classes.helperImage}
                />
                <Button className={classes.helperButton} onClick={() => setContactModalOpen(true)}>
                  Alege acest MONZA ARES HELPER
                </Button>
              </div>

              <ContactPopup
                locale={locale}
                location={location}
                open={contactModalOpen}
                handleClose={() => setContactModalOpen(false)}
                selectedTeamMemberUid={guide.data.helper.document.uid}
              />
            </Grid>}
          </Grid>
        </Container>

      </main>
    </>
  )
}

export default GuidePage

export const query = graphql`
  query PrismicGuideBySlug($slug: String!, $locale: String!) {
    guide: prismicGuide(uid: { eq: $slug }, lang: { eq: $locale }) {
      lang
      id
      data {
        title {
          text
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        helper {
          document {
            ... on PrismicHelper {
              id
              uid
              lang
              data {
                name {
                  text
                }               
                profile_image_no_background {
                  gatsbyImageData(layout: CONSTRAINED, width: 500)
                }
              }
            }
          }
        }
        content_image_desktop {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        content_image_mobile {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body1 {
          ... on PrismicGuideDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicGuideDataBody1DynamicBanner {
            id
            slice_type
            items {
              banner_type
              banner_text {
                html
                text
              }
              banner_text_2 {
                html
              }
              phone
              display_phone
              consultant_image {
                gatsbyImageData(layout: FIXED, width: 150)
              }
            }
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
        noindex
      }
      uid
    }
  }
`

GuidePage.propTypes = {
  data: PropTypes.shape({
    guide: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
